.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.text-right {
  text-align: right !important;
}
.text-left {
  text-align: left !important;
}

.margin-left-110 {
  margin-left: 110px;
}
.margin-left-210 {
  margin-left: 210px;
}

.header {
  padding: 10px 50px;
}

.header-option {
  padding: 10px 0 0;
  text-align: center;
}
.header-option a {
  text-decoration: none;
  color: black;
  font-weight: bold;
  font-size: 14px;
}
.principal-content {
  background: #f6f9ff;
  border-radius: 0 0 50px 50px;
}
.principal-left-content {
  box-sizing: border-box;
  padding: 80px 0 80px 25px;
}
.principal-left-p {
  padding: 20px 0 50px;
}
.principal-right-img {
  width: 100%;
}
.card-container {
  align-items: center;
}
.card {
  align-items: center;
  height: 480px;
  text-align: center;
  width: 100%;
}
.card img {
  margin: 40px 0;
  width: 50%;
}
.card h2 {
  font-size: 16px;
  font-weight: bold;
}
.card p {
  text-align: left !important;
  width: 80%;
}
.card div {
  font-weight: bold;
}
.card-button-container {
  display: flex;
  justify-content: center;
  margin: 100px 0px 0px 0px;
}
.card-start-button {
  width: 192px;
  height: 42px;
  font-size: 18px;
  line-height: 23px;
  font-weight: 400;
  border: solid black 2px;
  margin-right: 30px;
}

.card-contact-button {
  width: 192px;
  height: 42px;
  font-size: 18px;
  line-height: 23px;
  font-weight: 400;
  border: solid black 2px;
}

/* Testimonios */
.testimonios {
  background: #011628;
  padding: 0px 0;
  border-radius: 50px;
  margin-top: 100px;
  color: #fff;
}
.testimonios h1 {
  margin: 80px 50px;
  font-size: 48px;
}
.testimonios-web-button {
  margin: 0 50px;
  height: 42px;
  width: 253px;
  border-radius: 10px;
  display: block;
}
.testimonio-cliente {
  height: 111px;
  border: 1px solid #fff;
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  margin-bottom: 25px;
  margin-top: 25px;
}
.testimonio-cliente-img {
  display: flex;
  justify-content: center;
  align-items: center;
}
.testimonio-cliente-img img {
  height: 78px;
  width: 90px;
}
.testimonio-cliente-info {
  padding: 7px 10px 7px 0px;
}
.testimonio-cliente-info-stars {
  padding: 4px 4px 4px 0px;
}
.testimonio-cliente-info p {
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
}
.testimonio-mobile-start-button {
  width: 192px;
  height: 42px;
  font-size: 18px;
  line-height: 23px;
  font-weight: 400;
  border: solid white 2px;
  margin-top: 80px;
  display: initial;
  background-color: white;
}

/* Portafolio */

.portafolio {
  margin: 100px 0;
}

.portafolio-title-mobile {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0.2px;
  font-style: normal;
  display: block;
}
.portafolio-title-web {
  font-size: 48px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0.2px;
  font-style: normal;
  display: none;
}

.portafolio-principal-left-p-mobile {
  padding: 20px 0 50px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.3px;
  display: block;
}
.portafolio-principal-left-p-web {
  padding: 20px 0 50px;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.3px;
  display: none;
}

.portafolio-input-container-web {
  width: 470px;
  position: relative;
  z-index: 10;
  display: none;
}
.portafolio-email-container-web {
  height: 58px;
}
.portafolio-email-web {
  padding: 10px 165px 10px 15px;
  z-index: 10;
}
.portafolio-email-button-web {
  position: absolute;
  top: 11px;
  right: 10px;
  z-index: 12;
  width: 145px;
  font-size: 18px;
  line-height: 23px;
  font-weight: 400;
}

.portafolio-input-container-mobile {
  width: 100%;
}
.portafolio-email-container-mobile {
  height: 58px;
}

.portafolio-email-button-mobile {
  width: 100%;
  height: 42px;
  font-size: 18px;
  line-height: 23px;
  font-weight: 400;
}

.portafolio-integrations-container {
  justify-content: space-between;
  margin-top: 60px;
  margin-bottom: 50px;
}
.portafolio-couriers-container {
  justify-content: space-between;
  margin-bottom: 150px;
}

.portafolio-quote-container {
  display: flex;
}
.portafolio-quote-img-container {
  margin: auto 0px;
}

.portafolio-quote-img {
  width: 100%;
}
.portafolio-quote-right-web {
  padding-left: 35px;
  display: none;
}
.portafolio-quote-right-mobile {
  display: initial;
}

.portafolio-quote-left-title {
  font-size: 48px;
  font-weight: 600;
  line-height: 56px;
}
.portafolio-quote-left-p {
  padding: 20px 0 50px;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.3px;
}
.portafolio-buttons-container{
  display: flex;
}

.portafolio-quote-start-button {
  width: 192px;
  height: 42px;
  font-size: 18px;
  line-height: 23px;
  font-weight: 400;
  border: solid black 2px;
  margin-right: 30px;
  display: none;
}
.portafolio-quote-contact-button {
  width: 192px;
  height: 42px;
  font-size: 18px;
  line-height: 23px;
  font-weight: 400;
  border: solid black 2px;
  display: none;
}

.buttons-container-mobile{
  display: grid;
  justify-content: center;
}

.start-button-mobile {
  width: 268px;
  height: 42px;
  font-size: 18px;
  line-height: 23px;
  font-weight: 400;
  border: solid black 2px;
  margin-bottom: 20px;
}
.contact-button-mobile {
  width: 268px;
  height: 42px;
  font-size: 18px;
  line-height: 23px;
  font-weight: 400;
  border: solid black 2px;
}

/* solutions */

.solucion {
  margin: 150px 0 150px 0px;
  background: rgba(239, 45, 85, 0.02);
  border-radius: 60px;
  box-shadow: 0px 5px 15px 0px #c3c3c3;
  padding: 0px 20px;
}

.solucion-container {
  margin: 60px 0px 20px 50px;
  position: relative;
}
.solucion-title-container {
  margin: auto auto;
  padding: 0px 0px;
  display: none;
}
.solucion-title-container-mobile {
  margin: auto auto;
  padding: 0px 0px;
  display: block;
}
.solucion-line {
  height: 30px;
  width: 90%;
  border-bottom: solid 1px #99afd7;
  position: absolute;
  bottom: 0px;
  margin-left: -7px;
}
.solucion-title-container h1 {
  font-size: 48px;
  font-weight: 600;
  line-height: 56px;
}

.solucion-img-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.solucion-img-woman {
  width: 100%;
}
.solucion-pasos-container {
  margin: 20px 0px 80px 0px;
}

.solucion-pasos-title-etiquetado {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #42d6ed;
}

.solucion-pasos-title-inventario {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #772def;
}

.solucion-pasos-title-picking {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #ef2d55;
}

.solucion-pasos-texts {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #011628;
}
.margin-top-pasos {
  margin-top: 39px;
}
/* asesoria */
.asesoria {
  margin: 100px 0 100px 0px;
}
.asesoria h1 {
  font-size: 48px;
  font-weight: 600;
  line-height: 56px;
}
.asesoria-list {
  margin-top: 40px;
  position: relative;
}

.asesoria-list li {
  margin: 20px 10px;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.3px;
  color: #011628;
}
.asesoria-list li::marker {
  font-size: 24px;
}
.asesoria-line {
  height: 136px;
  border-left: 2px solid;
  position: absolute;
  top: 15px;
  left: 23px;
}
.asesoria-button {
  width: 253px;
  height: 42px;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  line-height: 23px;
  margin-top: 40px;
}
.asesoria-images {
  position: relative;
}
.asesoria-images-circle {
  position: absolute;
  top: 0;
  left: -75px;
}
.asesoria-images-phone {
  position: absolute;
  bottom: 0;
  right: 0;
}

/* experiencia */
.experiencia {
  margin: 200px 0px 200px 0px;
}
.experiencia-points-img {
  width: 100%;
}
.experiencia-title {
  font-size: 48px;
  font-weight: 600;
  line-height: 56px;
  display: none;
}
.experiencia-title-mobile {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  display: block;
}
.experiencia-p {
  padding: 20px 0 50px;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.3px;
  display: none;
}
.experiencia-p-mobile {
  padding: 20px 0 50px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.3px;
  display: block;
}

.experiencia-start-button {
  width: 192px;
  height: 42px;
  font-size: 18px;
  line-height: 23px;
  font-weight: 400;
  border: solid black 2px;
  margin-right: 30px;
}

.experiencia-contact-button {
  width: 192px;
  height: 42px;
  font-size: 18px;
  line-height: 23px;
  font-weight: 400;
  border: solid black 2px;
}
.experiencia-start-button {
  width: 192px;
  height: 42px;
  font-size: 18px;
  line-height: 23px;
  font-weight: 400;
  border: solid black 2px;
  margin-right: 30px;
}

.experiencia-contact-button {
  width: 192px;
  height: 42px;
  font-size: 18px;
  line-height: 23px;
  font-weight: 400;
  border: solid black 2px;
}

.container-footer{
  background-color: #f6f9ff;
}

.footer-web {
  background: #f6f9ff;
  align-items: center;
  text-align: center;
  margin: 100px 97.5px 20px 97.5px;
  height: 248px;
}

.footer-left-container {
  text-align: left;
}

.footer-logo {
  margin-bottom: 30px;
  height: 47px;
  width: 205px;
}

.footer-left-direction {
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  letter-spacing: 0.2px;
  font-weight: 400;
  margin-bottom: 30px;
}

.footer-left-socials-container {
  display: flex;
}
.footer-left-socials-img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  margin-right: 25px;
  margin-bottom: 25px;
}
.footer-right-container {
  text-align: right;
  height: 248px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: end;
}

.footer-input-container {
  width: 470px;
  position: relative;
  z-index: 10;
}

.footer-email-container {
  height: 58px;
}

.footer-email {
  padding: 10px 165px 10px 15px;
  z-index: 10;
}
.footer-email-button {
  position: absolute;
  top: 11px;
  right: 10px;
  z-index: 12;
  width: 145px;
  font-size: 18px;
  line-height: 23px;
  font-weight: 400;
}
.footer-right-links-container {
  display: flex;
  flex-direction: column;
}

.mt-10-links {
  margin-top: 10px;
}
.mt-10-links a {
  text-decoration: none;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #011628;
}

.footer-mobile {
  margin: 0px 12px;
}

.footer-input-container-mobile {
  width: 100%;
}

.footer-email-container-mobile {
  height: 58px;
}
.footer-email-button-mobile {
  width: 100%;
  height: 42px;
  font-size: 18px;
  line-height: 23px;
  font-weight: 400;
  margin-bottom: 20px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .header {
    padding: 10px 10px;
  }
  .header-option,
  .header-login {
    display: none !important;
  }
  .principal-left-content {
    box-sizing: border-box;
    padding: 50px 0;
    text-align: center;
  }

  .card {
    height: auto;
    padding-bottom: 40px;
  }
  .card img {
    margin: 40px 0;
    width: 20%;
  }
  .card-button-container {
    margin: 0px;
}

  .testimonios {
    border-radius: 25px;
  }
  .card-start-button {
    width: 268px;
    margin-right: 0px;
    margin-bottom: 25px;
  }

  .card-contact-button {
    width: 268px;
  }

  .testimonios h1 {
    margin: 70px 0px 34px 0px;
    font-size: 27px;
  }

  .testimonios-web-button {
    display: none;
  }

  .testimonio-cliente {
    margin: 20px 0px;
  }

  .testimonio-cliente:last-child {
    margin: 20px 0px 190px 0px;
  }
  .testimonio-cliente-info {
    padding: 7px 10px 0px 0px;
  }
  .testimonio-mobile-start-button {
    width: 268px;
  }

  .margin-left-110 {
    margin-left: 0px;
  }

  .margin-left-210 {
    margin-left: 0px;
  }

  .portafolio h1 {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0.2px;
    font-style: normal;
  }
  .portafolio-couriers-container {
    justify-content: left;
  }
  .portafolio-couriers-container {
    margin-bottom: 0px;
  }
  .portafolio-quote-container {
    flex-direction: column-reverse;
  }
  .solucion {
    margin: 100px 0px 100px 0px;
    background: none;
    border-radius: 0px;
    box-shadow: none;
    padding: 0px;
  }
  .solucion-container {
    margin: 60px 0px 20px 0px;
    position: relative;
  }
  .solucion-title-container-mobile h1 {
    font-size: 24px;
  }
  .solucion-img-container {
    justify-content: left;
    padding-left: 0px;
    margin-top: 30px;
  }
  .solucion-img-woman {
    width: 80%;
  }
  .solucion-line {
    display: none;
  }
  .margin-top-pasos {
    margin-top: 10px;
  }
  .asesoria h1 {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0.2;
  }
  .asesoria-list li {
    margin: 20px 10px;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.3px;
    color: #011628;
  }
  .asesoria-list li::marker {
    font-size: 20px;
  }
  .asesoria-line {
    display: none;
  }
  .asesoria-images {
    position: relative;
    margin-top: 150px;
  }
  .asesoria-images-circle {
    position: absolute;
    top: -120px;
    left: 50px;
    width: 70%;
  }
  .asesoria-images-phone {
    position: absolute;
    bottom: 0px;
    right: 0px;
    top: 40px;
    width: 50%;
  }
  .experiencia {
    display: flex;
    flex-direction: column-reverse;
    margin: 200px 0px 200px 0px;
  }
  .experiencia-button-container {
    text-align: center;
    display: none;
  }
  .experiencia-button-container-mobile {
    display: grid;
    justify-content: center;
  }

  .experiencia-start-button {
    width: 268px;
    margin-right: 0px;
  }

  .experiencia-contact-button {
    width: 268px;
    margin-top: 20px;
  }
  .experiencia-points-img {
    margin-top: 45px;
  }
  .footer-web {
    display: none;
  }
  .footer-right-container {
    text-align: left;
    display: block;
  }
  .footer-right-links-container {
    text-align: right;
    margin-top: -36px;
  }

  .mt-10-links {
    margin-top: 10px;
  }
  .mt-10-links a {
    text-decoration: none;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #011628;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

  .card {
    height: auto;
    padding-bottom: 40px;
  }
  .card img {
    margin: 40px 0;
    width: 20%;
  }
  .card-button-container {
    margin: 0px;
}
  .testimonios {
    border-radius: 25px;
  }
  .testimonios-web-button {
    display: none;
  }
  .testimonios h1 {
    margin: 50px 50px 40px 50px;
    font-size: 48px;
  }
  .testimonio-cliente {
    margin: 20px 0px 0px 0px;
  }
  .testimonio-cliente:last-child {
    margin: 20px 0px 190px 0px;
  }
  .testimonio-mobile-start-button {
    width: 268px;
  }
  .margin-left-110 {
    margin-left: 0px;
  }
  .margin-left-210 {
    margin-left: 0px;
  }
  .portafolio-title-mobile{
    font-size: 40px;
    line-height: 50px;
  }
  .portafolio-couriers-container {
    justify-content: left;
    margin-bottom: 25px;
  }
  .portafolio-quote-left-title {
    font-size: 40px;
    line-height: 50px;
  }
  .portafolio-quote-left-p {
    font-size: 20px;
    line-height: 25px;
  }
  .portafolio-principal-left-p-mobile{
    font-size: 20px;
    line-height: 25px;
  }
  .solucion-container {
    margin: 60px 0px 20px 0px;
  }
  .solucion-img-container {
    justify-content: left;
    padding: 0px;
  }
  .solucion-img-woman {
    width: 85%;
    margin-top: 30px;
  }
  .solucion-line {
    display: none;
  }
  .asesoria-images {
    position: relative;
    margin-top: 150px;
  }
  .asesoria-images-circle {
    position: absolute;
    top: -115px;
    left: 50px;
    width: 70%;
  }
  .asesoria-images-phone {
    position: absolute;
    bottom: 0px;
    right: 0px;
    top: 90px;
    width: 50%;
  }
  .experiencia {
    display: flex;
    flex-direction: column-reverse;
    margin: 300px 0px 200px 0px;
  }
  .experiencia-title-mobile {
    font-size: 40px;
    line-height: 50px;
}
  .experiencia-p-mobile {
    font-size: 20px;
    line-height: 25px;
}
  .experiencia-button-container {
    display: none;
  }
  .experiencia-button-container-mobile {
    display: grid;
    justify-content: center;
  }
  .experiencia-points-img {
    width: 80%;
    margin-top: 30px;
  }

  .footer-web {
    display: none;
  }
  .footer-right-container {
    text-align: left;
    display: block;
  }
  .footer-right-links-container {
    text-align: right;
    margin-top: -36px;
  }

  .mt-10-links {
    margin-top: 10px;
  }
  .mt-10-links a {
    text-decoration: none;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #011628;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

  .card {
    height: auto;
    padding-bottom: 40px;
  }
  .card img {
    margin: 40px 0;
    width: 20%;
  }
  .card-button-container {
    margin: 0px;
}
  .testimonios-web-button {
    display: none;
  }

  .portafolio-title-mobile {
    font-size: 48px;
    line-height: 60px;
}
.portafolio-principal-left-p-mobile {
  font-size: 28px;
  line-height: 36px;
}
.portafolio-quote-left-title{
  font-size: 48px;
  line-height: 60px;
}
.portafolio-quote-left-p{
  font-size: 25px;
  line-height: 35px;
}

.portafolio-couriers-container {
  justify-content: left;
  margin-bottom: 150px;
}

.solucion-title-container-mobile h1 {
  font-size: 48px;
  font-weight: 600;
  line-height: 56px;
}
.margin-top-pasos {
  margin-top: 0px;
}
.asesoria-line{
  display: none;
}
.asesoria-list li {
  font-size: 25px;
  line-height: 30px;
}

  .asesoria {
    margin: 100px 0 370px 0px;
}
  .asesoria-images-circle {
    top: -100px;
  }
  .asesoria-images-phone {
    top: 160px;

}
.experiencia-title-mobile{
    font-size: 48px;
    line-height: 56px;
}
.experiencia-p-mobile{
    font-size: 25px;
    line-height: 30px;

}

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

  .card-button-container{display: none;}

  .testimonio-cliente {
  width: 400px;
  }
  .testimonio-cliente:last-child{
  margin: 20px 0px 20px 130px;  
  }
  .testimonio-cliente-img img {
    margin-bottom: 10px;
  }
  .testimonio-mobile-start-button{
    display: none;
  }
  .testimonios-web-button {
    display: block;
  }
/* portafolios */

.portafolio {
    margin: 100px 0px 100px 0;
}

  .portafolio-title-mobile {display: none;}
  .portafolio-principal-left-p-mobile{display: none;}
  .portafolio-input-container-mobile{display: none;}
  .start-button-mobile{display: none;}
  .contact-button-mobile{display: none;}
  .portafolio-quote-right-mobile{display: none}
  
  .portafolio-title-web {display: initial; line-height: 50px;}
  .portafolio-principal-left-p-web{display: block;}
  .portafolio-input-container-web{display: block;}
  .portafolio-quote-start-button{display: block;}
  .portafolio-quote-right-web{display: block}
  .portafolio-quote-contact-button{display: block;}


  .solucion {
    margin: 50px 0 150px 0px;
}
.solucion-line{
  display: inline;
}
.solucion-img-woman {
  width: 70%;
  margin-top: 0px;
}
.margin-top-pasos {
  margin-top: 0px;
}

  .asesoria h1{
    text-align: left!important;
  }
  .asesoria div{
    text-align: left!important;
  }
 

  .experiencia{
    flex-direction: row;
    margin: 150px 0 70px 0;
  }

  .experiencia-title-mobile{
    display:none
  }
  .experiencia-p-mobile{
    display:none
  }

  .experiencia-title{
    display:block
  }
  .experiencia-p{
    display:block
  }
  .experiencia-button-container {
    text-align: left;
}
  .experiencia-button-container-mobile {
display: none;
}

  .experiencia-points-img {
    width: 90%;
}
.asesoria {
  margin: 0px 0 70px 0px;
}

.container-footer{
  margin: 0px;
  padding: 0px 41px;
  max-width: 100%;
  min-height: 292px;
  }

  .footer-input-container{
  padding: 0px;
  margin-top: 25px;
  }

  .footer-logo{
  margin-top: 50px;
  }

  .footer-mobile{
  display: none;
  }
  .footer-web{
  display: flex;
  margin: 50px 0px 20px 0px;
  }
  .footer-right-container {
    text-align: left;
    display: flex;
}

  .footer-right-links-container{
  flex-direction: column;
  margin-top: 0px;
  }

  .margin-left-110 {
    margin-left: 60px;
}

}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .margin-left-110 {
    margin-left: 60px;
  }
  .testimonio-cliente:last-child{
    margin: 20px 0px 20px 140px;
  }
  .margin-left-210 {
    margin-left: 170px;
  }
  .portafolio-quote-right {
    padding-left: 25px !important;
  }
  .portafolio-couriers-container {
    justify-content: space-between;
    margin-bottom: 100px;
  }
  .portafolio-quote-right {
    padding-left: 0px;
  }
  
}
@media only screen and (min-width: 1400px) {
  .testimonio-cliente:last-child{
    margin: 20px 0px 20px 210px;
  }
  .margin-left-110 {
    margin-left: 110px;
  }
  .margin-left-210 {
    margin-left: 210px;
  }
  .portafolio-quote-right {
    padding-left: 35px !important;
  }
  .portafolio-couriers-container {
    justify-content: space-between;
    margin-bottom: 100px;
  }
  .portafolio-quote-right {
    padding-left: 0px;
  }
  .asesoria-images-phone {
    top: 160px;
}
}

